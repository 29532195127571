import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Login from './pages/Login.vue';
import Timer from './pages/Timer.vue';
import Records from './pages/Records.vue';
import Numbers from './pages/Numbers.vue';
import Event from './pages/Event.vue';
import Register from './pages/Register.vue';
import Events from './pages/Events.vue';
import Results from './pages/Results.vue';
import Check from './pages/Check.vue';
import MainNavbar from './layout/MainNavbar.vue';
import EventNavbar from './layout/EventNavbar.vue';
import MainFooter from './layout/MainFooter.vue';

import About from "./pages/AboutUs.vue";
import Terms from "./pages/Terms.vue";
import Regulations from "./pages/Regulations.vue";
import ContactUs from "./pages/ContactUs.vue";

Vue.use(Router);

export default new Router({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'index',
            components: {default: Index, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/login',
            name: 'login',
            components: {default: Login, header: MainNavbar},
            props: {
                header: {colorOnScroll: 400}
            }
        },
        {
            path: '/pomiar',
            name: 'pomiar',
            components: {default: Timer, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400}
            }
        },
        {
            path: '/zapisy',
            name: 'zapisy',
            components: {default: Records, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400}
            }
        },
        {
            path: '/numbers',
            name: 'numbers',
            components: {default: Numbers, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400}
            }
        },
        {
            path: '/event/:name',
            name: 'event',
            components: {default: Event, header: EventNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 300},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/event/:name/register',
            name: 'register',
            components: {default: Register, header: EventNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 100},
                footer: {backgroundColor: 'black'}
            },
        },
        {
            path: '/events',
            name: 'events',
            components: {default: Events, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 100},
                footer: {backgroundColor: 'black'}
            },
        },
        {
            path: '/results',
            name: 'results',
            components: {default: Results, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 100},
                footer: {backgroundColor: 'black'}
            },
        },
        {
            path: '/check',
            name: 'check',
            components: {default: Check, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 100},
                footer: {backgroundColor: 'black'}
            },
        },
        {
            path: "/about-us",
            name: "about-us",
            components: {default: About, header: MainNavbar, footer: MainFooter},
            props: {header: {colorOnScroll: 450}}
        },
        {
          path: "/contact",
          name: "contact",
          components: {default: ContactUs, header: MainNavbar, footer: MainFooter},
          props: {
              header: {colorOnScroll: 65},
              footer: {type: 'default'}
          }
        },
        {
          path: "/terms",
          name: "terms",
          components: {default: Terms, header: MainNavbar, footer: MainFooter},
          props: {
              header: {colorOnScroll: 65},
              footer: {type: 'default'}
          }
        },
        {
          path: "/regulations",
          name: "regulations",
          components: {default: Regulations, header: MainNavbar, footer: MainFooter},
          props: {
              header: {colorOnScroll: 65},
              footer: {type: 'default'}
          }
        },
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    }
});
