<template>
  <div class="wrapper">
    <div class="page-header header-filter" filter-color="orange">
      <parallax
          class="page-header-image"
          style="background-image:url('/img/bg0.jpg'); background-position: center center;"
      >
      </parallax>

      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title" v-if="!query">Wyniki</h1>
            <h1 class="title" v-else>{{ query }}</h1>
            <span>* zacznij wpisywać nazwę wydarzenia, aby wyszukać</span>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="ml-auto">
            <el-select v-on:change="getEvents"
                       class="select-year"
                       placeholder="Wybierz rok..."
                       v-model="year"
            >
              <el-option
                  v-for="year in years"
                  class="dropdown-item"
                  :value="year"
                  :label="year"
                  :key="year"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 px-1" v-for="event in filteredEvents" v-on:click="toEvent(event.slug)">
            <card class="event" type="background"
                  v-bind:style="{ backgroundImage: 'url(' + event.cover + ')' }">
              <div class="stats-link pull-right" v-if="!event.is_ended">
                <i class="now-ui-icons sport_user-run"></i>Limit: {{ event.limit }}
              </div>
              <div class="stats-link pull-right" v-if="event.is_ended && event.members_count">
                <i class="now-ui-icons sport_user-run"></i>Zapisanych: {{ event.members_count }}
              </div>
              <div class="card-title text-left">
                <h3>{{ event.name }}</h3>
              </div>
              <div class="card-footer events text-left">
                <div class="stats">
                  <span><i class="now-ui-icons location_pin"></i>{{ event.city }}</span>
                  <span v-if="!event.is_ended">
                    <i class="now-ui-icons tech_watch-time"></i>za {{ event.event_start | moment("from", "now", true) }}
                  </span>
                  <span v-if="event.is_ended">
                    <i class="now-ui-icons tech_watch-time"></i>{{ event.event_start | moment("YYYY-MM-DD") }}
                  </span>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "../components/Cards/Card";
import EventsFilter from "../components/EventsFilter";

let moment = require('moment');

export default {
  metaInfo: {
    title: "Etiming",
    titleTemplate: "Wyniki sportowe | %s",
    meta: [{vmid: 'description', name: "description", content: "Lista wyników sportowych."}],
  },
  components: {Card, EventsFilter},
  bodyClass: "profile-page",
  data: function () {
    return {
      query: "",
      tag: null,
      events: [],
      tags: [],
      today: moment().format(),
      year: null,
      years: this.range(2021, new Date().getFullYear())
    };
  },
  mounted: function () {
    this.getEvents();
    // this.$refs.
  },
  computed: {
    filteredEvents: function () {
      return this.events.filter(({tag, name}) => (
          tag === this.tag || this.tag === null) && name.toLowerCase().includes(this.query.toLowerCase())
      )
    },
  },
  created() {
    window.addEventListener('keydown', this.foo);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.foo);
  },
  methods: {
    getEvents() {
      let data = {
        params: {
          'event_start__lt': moment().format(),
          'year': this.year,
          'ordering': '-event_start',
        }
      }
      this.$http.get(process.env.VUE_APP_BACKEND_URL + "/events/", data).then(response => {
        this.events = response.data
      });
    },
    setTag(value) {
      this.tag = value;
    },
    toEvent(slug) {
      this.$router.push({name: "event", params: {name: slug}})
    },
    range(start, end) {
      const length = end - start + 1;
      return Array.from({length}, (_, i) => end - i);
    },
    foo(e) {
      let input = String.fromCharCode(e.keyCode);
      if (/[a-zA-Z0-9-_ ]/.test(input) || e.key === "Backspace") {
        if (e.key === "Backspace") {
          this.query = this.query.slice(0, -1)
        } else {
          this.query += e.key
        }
      }
    }
  },
};
</script>
<style scoped>
.event {
  font-size: 11px;
  color: white;
  cursor: pointer;
}

.select-year {
  margin: 0 0 10px
}

.page-header {
  min-height: 500px !important
}

.card-footer.events {
  position: absolute;
  bottom: 40px;
}
</style>
