<template>
  <navbar
    position="fixed"
    type="black"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template slot-scope="{ toggle, isToggled }">
      <router-link v-popover:popover1 class="navbar-brand" to="/" v-if=""> etiming </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a class="nav-link" href="/" rel="tooltip">
          <i class="now-ui-icons transportation_air-baloon"></i>
          <p>Home</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/about-us" rel="tooltip">
          <i class="now-ui-icons users_circle-08"></i>
          <p>O nas</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/events" rel="tooltip">
          <i class="now-ui-icons sport_user-run"></i>
          <p>Zawody</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/results" rel="tooltip">
          <i class="now-ui-icons design_bullet-list-67"></i>
          <p>Wyniki</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/check" rel="tooltip">
          <i class="now-ui-icons business_chart-bar-32"></i>
          <p>Sprawdź się</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/contact" rel="tooltip">
          <i class="now-ui-icons objects_spaceship"></i>
          <p>Kontakt</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Twitter"
          data-placement="bottom"
          href="https://twitter.com/etimingPL"
          target="_blank"
        >
          <i class="fab fa-twitter"></i>
          <p class="d-lg-none d-xl-none">Twitter</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Like us on Facebook"
          data-placement="bottom"
          href="https://www.facebook.com/etimingPL"
          target="_blank"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Instagram"
          data-placement="bottom"
          href="https://www.instagram.com/etimingPL"
          target="_blank"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, NavbarToggleButton, Navbar, NavLink } from "@/components";
import { Popover } from "element-ui";
export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    DropDown,
    Navbar,
    NavbarToggleButton,
    NavLink,
    [Popover.name]: Popover,
  },
};
</script>

<style scoped>
.navbar-brand {
  color: #fff !important;
  font-weight: bold;
}
</style>
