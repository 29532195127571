<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <tabs
            slot="raw-content"
            centered
            tab-content-classes="tab-content-padding"
        >
          <tab-pane
              class="results"
              v-for="distance_results in results.filter(v => v.results.length > 0)"
              :key="distance_results.id">
            <template slot="label">
              {{ distance_results.name }}
            </template>
            <el-table
                class="table-striped"
                :data="distance_results.results"
                v-if="distance_results.results.length > 0"
                :row-class-name="tableRowClassName"
                :cell-class-name="resultClassName"
            >
              <el-table-column
                  type="index"
                  :index="indexMethod"
                  width="40"
              ></el-table-column>
              <el-table-column min-width="30" prop="country">
                <div slot-scope="props">
                  <country-flag :country="props.row.country" size="small"/>
                </div>
              </el-table-column>
              <el-table-column
                  min-width="45"
                  prop="number"
                  label="Nr"
              ></el-table-column>
              <el-table-column
                  min-width="140"
                  prop="name"
                  label="Nazwisko i imię"
              ></el-table-column>
              <el-table-column
                  min-width="60"
                  prop="date_of_birth"
                  label="Rok ur."
                  align="center"
              ></el-table-column>
              <el-table-column
                  min-width="50"
                  align="center"
                  prop="category"
                  label="Kat."
                  :filters="distance_results.categories"
                  :filter-method="filterResultByCategory"
                  :filter-multiple="false"
              ></el-table-column>
              <el-table-column
                  min-width="50"
                  align="center"
                  prop="sex"
                  label="Płeć"
                  :filters="[
                    { text: 'K', value: 'K' },
                    { text: 'M', value: 'M' },
                  ]"
                  :filter-method="filterResultBySex"
                  :filter-multiple="false"
              ></el-table-column>
              <el-table-column
                  min-width="120"
                  prop="club"
                  label="Klub"
              ></el-table-column>
              <el-table-column
                  min-width="120"
                  prop="city"
                  label="Miasto"
              ></el-table-column>
              <el-table-column
                  min-width="100"
                  prop="time"
                  label="Czas"
                  align="center"
              ></el-table-column>
            </el-table>
          </tab-pane>
          <p v-html="event.results" v-if="event.results"></p>
          <button
              @click.prevent="download(result.file, result.filename)"
              type="button"
              class="btn btn-github btn-default"
              v-for="result in event.result_files"
          >
            <i class="now-ui-icons arrows-1_cloud-download-93"></i
            >&nbsp;&nbsp;
            {{ result.filename }}
          </button>
        </tabs>
      </div>
    </div>
  </div>
</template>
<script>
import {Table, TableColumn} from "element-ui";
import {Checkbox, Modal, TabPane, Tabs} from "@/components";
import NCheckbox from "../../components/Inputs/Checkbox";
import md5 from "js-md5";
import NButton from "../../components/Button";
import {saveAs} from "file-saver";
import CountryFlag from "vue-country-flag";
import results from "../Results.vue";

var moment = require("moment");

export default {
  components: {
    Modal,
    NButton,
    NCheckbox,
    Tabs,
    TabPane,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox,
    CountryFlag,
  },
  props: {
    event: {},
    results: {},
  },
  methods: {
    indexMethod: function (index) {
      return index + 1 + ".";
    },
    download: function (url, filename) {
      this.$http
          .get(url, {responseType: "blob"})
          .then((response) => {
            saveAs(response.data, filename);
          })
          .catch(function (err) {
            console.log(err.message);
          });
    },
    filterTag: function (value, row) {
      return row.status_payment === value;
    },
    filterResultBySex: function (value, row) {
      return row.sex === value;
    },
    filterResultByCategory: function (value, row) {
      return row.category === value;
    },
    tableRowClassName: function ({row}) {
      if (row.sex == "K") {
        return "woman";
      }
    },
    resultClassName: function ({row, column, rowIndex, columnIndex}) {
      if (column.property == "time") {
        return "result-column";
      }
    },
    getMD5Sum: function (crc, amount) {
      return md5(
          `${this.clientId()}&${amount}&${crc}&${process.env.VUE_APP_TPAY_CODE}`
      );
    },
    etimingUrl: function () {
      return `${process.env.VUE_APP_CLIENT_URL}/event/${this.event.slug}`;
    },
    clientId: function () {
      return `${process.env.VUE_APP_TPAY_CLIENT}`;
    },
  },
  data: function () {
    return {
      today: moment().format(),
    };
  },
};
</script>
<style>
.table-striped .tab-content.tab-content-padding {
  padding: 20px;
}

.table-striped {
  font-size: 12px;
}

.el-table td {
  padding: 0;
}

.el-table .cell {
  white-space: normal;
  word-break: normal !important;
}

.results {
  margin-bottom: 100px;
}

.el-table .woman {
  color: black;
}

.el-table .result-column {
  font-weight: 600;
}
</style>
