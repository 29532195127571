<template>
  <div class="wrapper">
    <div class="page-header header-filter" filter-color="orange">
      <parallax
          class="page-header-image"
          style="background-image:url('/img/check.jpg'); background-position: center center;"
      >
      </parallax>

      <div class="content-center">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h1 class="title">Sprawdź swoje wyniki</h1>
            <span>* zacznij wpisywać swoje nazwisko</span>
          </div>
        </div>
        <div class="row margin">
          <div class="col-md-6 ml-auto mr-auto">
            <fg-input></fg-input>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Card from "../components/Cards/Card";
import EventsFilter from "../components/EventsFilter";
import FgInput from "../components/Inputs/formGroupInput.vue";

let moment = require('moment');

export default {
  metaInfo: {
    title: "Etiming",
    titleTemplate: "Wyniki sportowe | %s",
    meta: [{vmid: 'description', name: "description", content: "Lista wyników sportowych."}],
  },
  components: {FgInput, Card, EventsFilter},
  bodyClass: "index-page",
  data: function () {
    return {
      query: "",
      tag: null,
      events: [],
      tags: [],
      today: moment().format(),
      year: null,
      years: this.range(2021, new Date().getFullYear())
    };
  },
  mounted: function () {
    this.getEvents();
    // this.$refs.
  },
  computed: {
    filteredEvents: function () {
      return this.events.filter(({tag, name}) => (
          tag === this.tag || this.tag === null) && name.toLowerCase().includes(this.query.toLowerCase())
      )
    },
  },
  created() {
    window.addEventListener('keydown', this.foo);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.foo);
  },
  methods: {
    getEvents() {
      let data = {
        params: {
          'event_start__lt': moment().format(),
          'year': this.year,
          'ordering': '-event_start',
        }
      }
      this.$http.get(process.env.VUE_APP_BACKEND_URL + "/events/", data).then(response => {
        this.events = response.data
      });
    },
    setTag(value) {
      this.tag = value;
    },
    toEvent(slug) {
      this.$router.push({name: "event", params: {name: slug}})
    },
    range(start, end) {
      const length = end - start + 1;
      return Array.from({length}, (_, i) => end - i);
    },
    foo(e) {
      let input = String.fromCharCode(e.keyCode);
      if (/[a-zA-Z0-9-_ ]/.test(input) || e.key === "Backspace") {
        if (e.key === "Backspace") {
          this.query = this.query.slice(0, -1)
        } else {
          this.query += e.key
        }
      }
    }
  },
};
</script>
<style scoped>
</style>
