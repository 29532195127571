<template>
  <div class="wrapper">
    <div class="page-header header-filter" filter-color="orange">
      <parallax class="page-header-image" v-bind:style="{ backgroundImage: 'url(' + event.cover + ')' }"></parallax>
      <div class="container">
        <div class="photo-container" v-if="event.logo">
          <img v-bind:src="event.logo" alt/>
        </div>
        <div class="no-photo" v-if="!event.logo">
        </div>
        <h3 class="title" id="title">{{ event.name }}</h3>
        <p class="category">{{ event.subtitle }}</p>
        <div class="content" v-if="!!event.name && !event.is_ended">
          <div class="stats-description">
            <h2>{{ event.members_count || 0 }}</h2>
            <p>Zapisanych</p>
          </div>
          <div class="stats-description" v-if="event.tpay">
            <h2>{{ event.members_paid || 0 }}</h2>
            <p>Potwierdzonych</p>
          </div>
          <div class="stats-description">
            <h2>{{ event.limit - event.members_count }}</h2>
            <p>Zostało miejsc</p>
          </div>
          <div class="stats-description">
            <h2>{{ event.event_start | moment("from", "now", true) }}</h2>
            <p>Do startu</p>
          </div>
          <div class="social-description">
            <h2>{{ event.payment }} zł</h2>
            <p>Opłata</p>
          </div>
        </div>
        <div class="content" v-else-if="event.is_ended && event.participants">
          <div class="stats-description">
            <h2>{{ event.event_start | moment("Do MMM") }}</h2>
            <p>Dzień zawodów</p>
          </div>
          <div class="stats-description">
            <h2>{{ event.participants }}</h2>
            <p>Uczestników</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <results :event="event" :members="members" :results="results" v-if="loaded && this.event.is_ended"></results>
        <tabs-section :event="event" :members="members" v-else-if="loaded"></tabs-section>
        <router-link
            :to="{ name: 'register', params: { name: event.slug } }"
            class="nav-link btn btn-danger register-button"
            v-if="!event.is_ended"
        >
          <i class="now-ui-icons arrows-1_share-66"></i>
          <p>Zapisz się</p>
        </router-link>
      </div>
    </div>
    <div class="cd-section" id="contactus" v-if="event.lat > 0 && event.lng > 0">
      <div class="contactus-2">
        <gmap-map
            id="map"
            :class="whatMap()"
            :center="{ lat: parseFloat(event.lat), lng: parseFloat(event.lng) }"
            :options="options"
            :zoom="15"
            map-type-id="terrain"
        >
          <gmap-marker :position="{ lat: parseFloat(event.lat), lng: parseFloat(event.lng) }"/>
        </gmap-map>
        <card
            class="col-lg-8 col-md-10"
            type="contact"
            raised
            header-classes="text-center"
            v-if="event.office && event.contact"
        >
          <h4 slot="header" class="card-title">Organizator wydarzenia</h4>
          <div class="row">
            <div class="col-md-6">
              <info-section type="default" icon="now-ui-icons tech_mobile">
                <h5 class="info-title">Skontaktuj się</h5>
                <p v-html="event.contact"></p>
              </info-section>
            </div>
            <div class="col-md-6">
              <info-section type="default" icon="now-ui-icons location_pin">
                <h5 class="info-title">Miejsce</h5>
                <p v-html="event.office"></p>
              </info-section>
            </div>
          </div>
        </card>
      </div>
    </div>
    <modal :show.sync="modal.payment">
      <h4 slot="header" class="title title-up">Opłata za bieg</h4>
      <p v-html="modal.text"></p>
      <template slot="footer">
        <n-button type="danger" @click.native="modal.payment = false">Zamknij</n-button>
      </template>
    </modal>
  </div>
</template>
<script>
import {Card, InfoSection, Tabs, TabPane, Modal} from "@/components";
import Register from "./components/Register";
import TabsSection from "./components/Tabs";
import Results from "./components/Results";

let moment = require("moment");
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import {jwtDecode} from "jwt-decode";
import NButton from "../components/Button.vue";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDQQlknR1C1802oosh6m30De0X9Zi1H6B0",
  },
});

export default {
  name: "Event",
  bodyClass: "profile-page",
  components: {
    NButton,
    Card,
    InfoSection,
    Tabs,
    TabPane,
    Register,
    TabsSection,
    Results,
    Modal,
  },
  data: function () {
    return {
      modal: {
        text: "",
        payment: false,
      },
      options: {
        styles: [
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#ffffff",
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                saturation: 36,
              },
              {
                color: "#333333",
              },
              {
                lightness: 40,
              },
            ],
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#f2f2f2",
              },
              {
                lightness: 19,
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#fefefe",
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#fefefe",
              },
              {
                lightness: 17,
              },
              {
                weight: 1.2,
              },
            ],
          },
        ],
      },
      event: {
        office: "",
        contact: "",
        lat: "",
        lng: "",
      },
      members: [],
      results: [],
      today: moment().format(),
      loaded: false,
    };
  },
  methods: {
    whatMap: function () {
      return this.event.contact && this.event.office ? "map" : "big-map";
    },
    getEvent: async function (name) {
      let that = this;
      await this.$http.get(process.env.VUE_APP_BACKEND_URL + "/events/" + name + "/").then((response) => {
        that.event = response.data;
        if (!that.event.is_ended) {
          that.getMembers(that.event.id);
        }
        that.getResults(that.event.slug);
        that.eventEmit(that.event);
        this.loaded = true;
      });
    },
    paymentMessage: function () {
      let token = this.$route.query.data;
      let decoded = null
      try {
        decoded = jwtDecode(token);
      } catch (InvalidTokenError) {
        console.log("Decoded token error");
      }
      if (decoded && decoded['result']) {
        if (decoded['result'] === "SUCCESS") {
          this.modal.text = "Płatność zakończona sukcesem, zatwierdzenie uczestnika nastąpi w ciągu kilku minut."
        } else {
          this.modal.text = "Płatność odrzucona."
        }
        this.modal.payment = true;
      }
    },
    getMembers: async function (id) {
      let that = this;
      await this.$http
          .get(process.env.VUE_APP_BACKEND_URL + "/members/" + id + "/event/")
          .then((response) => (that.members = response.data));
    },
    getResults: async function (slug) {
      let that = this;
      await this.$http
          .get(process.env.VUE_APP_BACKEND_URL + "/events/" + slug + "/results/")
          .then((response) => (that.results = response.data));
    },
    eventEmit: function (event) {
      this.$root.$emit("event", event);
    },
  },
  created: async function () {
    await this.getEvent(this.$route.params.name);
    this.paymentMessage();
  },
  metaInfo: function () {
    return {
      meta: [
        {
          property: "og:image",
          content: this.event.cover,
        },
        {
          property: "og:title",
          content: this.event.name,
        },
      ],
      title: this.event.name,
      titleTemplate: "%s | Etiming",
    };
  },
};
</script>
<style scoped>
.page-header {
  min-height: 500px !important;
}

.profile-page .content .stats-description {
  display: inline-block;
  max-width: 150px;
  width: 155px;
  text-align: center;
  margin: 15px 0 0px;
}

.profile-page .content .stats-description h2 {
  margin-bottom: 5px;
}

.profile-page .content {
  max-width: 800px;
}

.register-button {
  display: none;
}

.no-photo {
  height: 120px
}

@media (max-width: 991px) {
  .register-button {
    display: block;
  }
}
</style>
