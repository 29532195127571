<template>
  <div class="section section-nucleo-icons">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <h2 class="title">Pomiar czasu</h2>
          <div class="description">
            <p>Jako organizator zawodów sportowych doskonale wiesz, jak kluczowe znaczenie ma precyzyjny i niezawodny
            pomiar czasu dla sukcesu całej imprezy. Dlatego oferujemy Ci nasze profesjonalne usługi, które nie tylko
            gwarantują najwyższą jakość pomiaru, ale także pozwolą Ci skupić się na innych aspektach organizacji
            wydarzenia, mając pewność, że czas jest w najlepszych rękach.</p>

            <p>Nasza oferta to kompleksowe rozwiązania stworzone z myślą o organizatorach takich jak Ty. Dysponujemy
            najnowocześniejszymi urządzeniami pomiarowymi oraz zaawansowanym oprogramowaniem, które umożliwi Ci łatwą
            analizę i prezentację wyników. Nasz zespół jest elastyczny i otwarty na współpracę – dostosujemy nasze
            usługi do Twoich indywidualnych potrzeb i wymagań, zapewniając pełne wsparcie na każdym etapie.</p>

            <p>Naszym celem jest pomóc Ci stworzyć wydarzenie, które będzie nie tylko profesjonalne, ale także
            niezapomniane dla uczestników i widzów. Zadbamy o to, aby pomiar czasu był perfekcyjny i w pełni niezawodny,
            dodając Twoim zawodom prestiżu i wartości. Skontaktuj się z nami już dziś, aby dowiedzieć się więcej o
            naszej ofercie i wspólnie podnieść Twoje zawody na wyższy poziom!</p>
          </div>
          <a href="/" class="btn btn-primary btn-round btn-lg">Nasza oferta</a>&nbsp;
          <a href="/about-us" class="btn btn-primary btn-simple btn-round btn-lg">O nas</a>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="icons-container">
            <i class="now-ui-icons ui-1_send"></i>
            <i class="now-ui-icons ui-2_like"></i>
            <i class="now-ui-icons transportation_air-baloon"></i>
            <i class="now-ui-icons text_bold"></i>
            <i class="now-ui-icons tech_headphones"></i>
            <i class="now-ui-icons emoticons_satisfied"></i>
            <i class="now-ui-icons shopping_cart-simple"></i>
            <i class="now-ui-icons objects_spaceship"></i>
            <i class="now-ui-icons media-2_note-03"></i>
            <i class="now-ui-icons ui-2_favourite-28"></i>
            <i class="now-ui-icons design_palette"></i>
            <i class="now-ui-icons clothes_tie-bow"></i>
            <i class="now-ui-icons location_pin"></i>
            <i class="now-ui-icons objects_key-25"></i>
            <i class="now-ui-icons travel_istanbul"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
.description {
  font-size: 16px
}
</style>
