<template>
  <div class="wrapper">
    <div class="page-header header-filter" filter-color="black">
      <parallax class="page-header-image" style="background-image: url('img/background.jpg')"></parallax>
      <div class="container">
        <div class="content-center brand">
          <img class="n-logo" src="img/logo.svg" alt=""/>
        </div>
      </div>
    </div>

    <nucleo-icons-section></nucleo-icons-section>
    <div class="section">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12">
            <h2 class="title">Nasz sprzęt</h2>
            <div class="description">
              <p>
                Jako firma specjalizująca się w precyzyjnym pomiarze czasu, stawiamy na najwyższą jakość i niezawodność
                naszych usług. Dlatego z pełnym przekonaniem korzystamy z najlepszego sprzętu na świecie, stworzonego
                przez niemiecką firmę Race Result – niekwestionowanego lidera w branży urządzeń do pomiaru czasu. Ich
                zaawansowane rozwiązania są wykorzystywane podczas największych i najbardziej prestiżowych imprez
                sportowych na całym globie, co jest gwarancją ich niezawodności i precyzji.
              </p>

              <p>Dzięki temu, że używamy technologii od Race Result, możemy zapewnić Ci najwyższy standard obsługi i
                bezbłędny pomiar czasu na Twoich zawodach. Jednak sam sprzęt to nie wszystko – nieustannie podnosimy
                nasze
                kwalifikacje, szkolimy się i wprowadzamy najnowsze rozwiązania technologiczne, aby sprostać nawet
                najbardziej wymagającym oczekiwaniom.</p>

              <p>Jakość jest dla nas priorytetem, ponieważ wierzymy, że to właśnie ona stanowi klucz do sukcesu każdego
                wydarzenia. Naszym celem jest dostarczanie usług, które nie tylko spełniają, ale wręcz przewyższają
                Twoje
                oczekiwania. Razem z nami możesz być pewien, że pomiar czasu podczas Twojej imprezy sportowej będzie na
                światowym poziomie!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="separator-line separator-primary"></div>
    <signup-form></signup-form>
    <download-section></download-section>
  </div>
</template>
<script>
import NucleoIconsSection from "./components/NucleoIconsSection";
import SignupForm from "./components/SignupForm";
import ExamplesSection from "./components/ExamplesSection";
import DownloadSection from "./components/DownloadSection";

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    NucleoIconsSection,
    SignupForm,
    ExamplesSection,
    DownloadSection,
  },
};
</script>
<style scoped>

.category-absolute a.innov8tech {
  color: #ccc;
  background-color: #212121;
  padding: 5px;
  font-weight: bold;
  text-decoration: none;
}

.category-absolute a.innov8tech span {
  font-style: italic;
}

@media screen and (max-width: 991px) {
  .category-absolute {
    display: none;
  }
}


</style>
